import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addGrammarContentAsync,
  getAllgrammarContentAsync,
  grammarContentByIdAsync,
  updateGrammarContentAsync,
  deleteGrammarContentAsync,
} from "./grammar.async";

const initialState = {
  grammarLoader: false,
  addGrammar: [],
  allLoader: false,
  allgrammar: [],
  getGrammarById: [],
  updateGrammar: [],
  deleteLoader: false,
  deleteGrammar: [],
};

export const grammarSlice = createSlice({
  name: "grammar",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addGrammarContentAsync.pending,
        grammarContentByIdAsync.pending,
        updateGrammarContentAsync.pending
      ),
      (state) => {
        state.grammarLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(deleteGrammarContentAsync.pending), (state) => {
      state.deleteLoader = true;
    });

    builder.addMatcher(
      isAnyOf(deleteGrammarContentAsync.fulfilled),
      (state, action) => {
        state.deleteLoader = false;
        state.deleteGrammar = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(addGrammarContentAsync.fulfilled),
      (state, action) => {
        state.grammarLoader = false;
        state.addGrammar = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(getAllgrammarContentAsync.pending), (state) => {
      state.allLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllgrammarContentAsync.fulfilled),
      (state, action) => {
        state.allLoader = false;
        state.allgrammar = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllgrammarContentAsync.rejected),
      (state, action) => {
        state.allLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(grammarContentByIdAsync.fulfilled),
      (state, action) => {
        state.grammarLoader = false;
        state.getGrammarById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateGrammarContentAsync.fulfilled),
      (state, action) => {
        state.grammarLoader = false;
        state.updateGrammar = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteGrammarContentAsync.rejected),
      (state, action) => {
        state.deleteLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(
        addGrammarContentAsync.rejected,
        grammarContentByIdAsync.rejected,
        updateGrammarContentAsync.rejected
      ),
      (state, action) => {
        state.grammarLoader = false;
      }
    );
  },
  reducers: {
    emptygrammar: (state) => initialState,
  },
});
export const { emptygrammar } = grammarSlice.actions;

export default grammarSlice.reducer;
