import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  uploadVocabularyExcelAsync,
  getAllVocWordAsync,
  addVocWordAsync,
  getVocWordByIdAsync,
  updateVocWordByIdAsync,
  deleteVocWordByIdAsync,
} from "./vocabulary.async";

const initialState = {
  vocabLoader: false,
  vocabBulkLoader: false,
  bulkVocab: [],
  addVocab: [],
  updateVocabById: [],
  getVocabById: [],
  allVocabLoader: false,
  getAllWords: [],
  deleteLoader: false,
  deletedData: [],
};

export const vocabularySlice = createSlice({
  name: "Vocabulary",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addVocWordAsync.pending,
        getVocWordByIdAsync.pending,
        updateVocWordByIdAsync.pending
      ),
      (state) => {
        state.vocabLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(uploadVocabularyExcelAsync.pending), (state) => {
      state.vocabBulkLoader = true;
    });

    builder.addMatcher(isAnyOf(deleteVocWordByIdAsync.pending), (state) => {
      state.deleteLoader = true;
    });

    builder.addMatcher(
      isAnyOf(uploadVocabularyExcelAsync.fulfilled),
      (state, action) => {
        state.vocabBulkLoader = false;
        state.bulkVocab = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadVocabularyExcelAsync.rejected),
      (state, action) => {
        state.vocabBulkLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getAllVocWordAsync.pending), (state) => {
      state.vocabLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllVocWordAsync.fulfilled),
      (state, action) => {
        state.vocabLoader = false;
        state.getAllVocabs = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(addVocWordAsync.fulfilled), (state, action) => {
      state.vocabLoader = false;
      state.addVocab = action.payload;
    });

    builder.addMatcher(
      isAnyOf(getVocWordByIdAsync.fulfilled),
      (state, action) => {
        state.vocabLoader = false;
        state.getVocabById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateVocWordByIdAsync.fulfilled),
      (state, action) => {
        state.vocabLoader = false;
        state.updateVocabById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteVocWordByIdAsync.fulfilled),
      (state, action) => {
        state.deleteLoader = false;
        state.deletedData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteVocWordByIdAsync.rejected),
      (state, action) => {
        state.deleteLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllVocWordAsync.rejected),
      (state, action) => {
        state.vocabLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(
        addVocWordAsync.rejected,
        getVocWordByIdAsync.rejected,
        updateVocWordByIdAsync.rejected
      ),
      (state, action) => {
        state.vocabLoader = false;
      }
    );
  },
  reducers: {
    emptyVocabulary: (state) => initialState,
  },
});

export const { emptyVocabulary } = vocabularySlice.actions;

export default vocabularySlice.reducer;
