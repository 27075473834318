import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosClient } from "redux/AxiosClient";

export const uploadWordOfTheDayExcelAsync = createAsyncThunk(
  "admin/uploadWordOfTheDayExcel",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadWordOfTheDayExcel`,
      payload,
      toolkit
    );
  }
);

export const addWordsAsync = createAsyncThunk(
  "admin/addWords",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addWords`, payload, toolkit);
  }
);

export const getAllWordOfTheDayAsync = createAsyncThunk(
  "admin/getAllWordOfTheDay",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllWordOfTheDay?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const getWordOfTheDayByIdAsync = createAsyncThunk(
  "admin/getWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getWordOfTheDayById/${payload}`,
      [],
      toolkit
    );
  }
);

export const bulkquestionInAsync = createAsyncThunk(
  "admin/bulkquestionInAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/uploadBulkQuestions`, payload, toolkit);
  }
);
export const bulkuploadquestionAsync = createAsyncThunk(
  "admin/uploadBulkQuestion",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadBulkQuestion`,
      payload,
      toolkit,
      "multipart/form-data"
    );
  }
);

export const updateQuestionsStatusAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PATCH",
      `/updateQuestionsStatus`,
      payload,
      toolkit
    );
  }
);

export const updateWordOfTheDayByIdAsync = createAsyncThunk(
  "admin/updateWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateWordOfTheDayById`,
      payload,
      toolkit
    );
  }
);

export const createQuestionBankAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createQuestionBank`, payload, toolkit);
  }
);
export const getQuestionByIdAsync = createAsyncThunk(
  "admin/getQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getQuestionById/${payload}`, [], toolkit);
  }
);
export const updateQuestionAsync = createAsyncThunk(
  "admin/updateQuestionBankId",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateQuestionBankId`, payload, toolkit);
  }
);

export const deleteWordOfTheDayByIdAsync = createAsyncThunk(
  "admin/deleteWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteWordOfTheDayById/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteQuestionAsync = createAsyncThunk(
  "admin/deleteQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteQuestionById/${payload}`,
      [],
      toolkit
    );
  }
);
export const getChapterByMultipleSubjectIdAsync = createAsyncThunk(
  "admin/getChapterByMultipleSubjectId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getChapterByMultipleSubjectId`,
      payload,
      toolkit
    );
  }
);
export const getAllBulkFilesAsync = createAsyncThunk(
  "admin/getAllBulkFiles",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllBulkFiles?page=${payload.page}&limit=${payload.limit}&fileType=${payload.fileType}`,
      [],
      toolkit
    );
  }
);

// get question for scholarship test
export const getQuestionScholarshipTestAsync = createAsyncThunk(
  "admin/getQuestionScholarshipTestAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getScholarshipQuestions`,
      payload,
      toolkit
    );
  }
);
