import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  uploadWordOfTheDayExcelAsync,
  getAllWordOfTheDayAsync,
  addWordsAsync,
  getWordOfTheDayByIdAsync,
  updateWordOfTheDayByIdAsync,
  deleteWordOfTheDayByIdAsync,
} from "./wordbank.async";

const initialState = {
  wordBankLoader: false,
  wordBulkLoader: false,
  bulkWord: [],
  wordofday: [],
  addLoader: false,
  addWord: [],
  updateWordById: [],
  getWordById: [],
  allWordLoader: false,
  getAllWords: [],
  deleteLoader: false,
  deletedData: [],
};

export const wordBankSlice = createSlice({
  name: "Word Bank",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addWordsAsync.pending,
        getWordOfTheDayByIdAsync.pending,
        updateWordOfTheDayByIdAsync.pending
      ),
      (state) => {
        state.wordBankLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadWordOfTheDayExcelAsync.pending),
      (state) => {
        state.wordBulkLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteWordOfTheDayByIdAsync.pending),
      (state) => {
        state.deleteLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadWordOfTheDayExcelAsync.fulfilled),
      (state, action) => {
        state.wordBulkLoader = false;
        state.bulkWord = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadWordOfTheDayExcelAsync.fulfilled),
      (state, action) => {
        state.wordBulkLoader = false;
        state.bulkWord = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadWordOfTheDayExcelAsync.rejected),
      (state, action) => {
        state.wordBulkLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getAllWordOfTheDayAsync.pending), (state) => {
      state.wordBankLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllWordOfTheDayAsync.fulfilled),
      (state, action) => {
        state.wordBankLoader = false;
        state.getAllWords = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(addWordsAsync.fulfilled), (state, action) => {
      state.wordBankLoader = false;
      state.addWord = action.payload;
    });

    builder.addMatcher(
      isAnyOf(getWordOfTheDayByIdAsync.fulfilled),
      (state, action) => {
        state.wordBankLoader = false;
        state.getWordById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateWordOfTheDayByIdAsync.fulfilled),
      (state, action) => {
        state.wordBankLoader = false;
        state.updateWordById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteWordOfTheDayByIdAsync.fulfilled),
      (state, action) => {
        state.deleteLoader = false;
        state.deletedData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteWordOfTheDayByIdAsync.rejected),
      (state, action) => {
        state.deleteLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllWordOfTheDayAsync.rejected),
      (state, action) => {
        state.wordBankLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(
        addWordsAsync.rejected,
        getWordOfTheDayByIdAsync.rejected,
        updateWordOfTheDayByIdAsync.rejected
      ),
      (state, action) => {
        state.wordBankLoader = false;
      }
    );
  },
  reducers: {
    emptyWordBank: (state) => initialState,
  },
});

export const { emptyWordBank } = wordBankSlice.actions;

export default wordBankSlice.reducer;
